<template>
  <div class="error-page flex justify-center mg-t-50 pd-t-50">
    <div class="box w-500 flex align-center direction-column">
      <h1>404</h1>
      <h2><span>P</span>age <span>N</span>ot <span>F</span>ound</h2>
      <router-link :to="locationPath" class="btn btn--green">{{textBtn}}</router-link>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Error',
  data () {
    return {
      locationPath: '/login',
      textBtn: this.$t('global.back')
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed'
    })
  },
  mounted () {
    if (this.isAuthed) {
      this.locationPath = '/places'
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>

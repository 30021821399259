<template>
  <div id="app">
    <div v-if="!isLoading">
      <router-view/>
    </div>
    <Splash v-if="isLoading"/>
    <class-select v-if="!isLoading"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Auth, User } from '@/api/api'
import Splash from '@/components/splash/splash'
// import ClassSelect from '@/components/classSelect/classSelect'

export default {
  name: 'App',
  components: { Splash },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      language: 'auth/language'
    })

  },
  mounted () {
    console.log(this.language)
    this.$i18n.locale = this.language
    console.log(this.$i18n.locale)

    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
    this.checkAuth()
  },
  methods: {
    async checkAuth () {
      // const token = window.localStorage.getItem('loginToken')
      var token = window.localStorage.getItem('loginToken')

      if (!token) {
        token = window.sessionStorage.getItem('loginToken')
      }
      if (token) {
        Auth.setToken(token)

        try {
          const { data } = await User.me()

          this.$store.dispatch('auth/login', {
            token,
            user: {
              ...data
            },
            remember: true
          })
        } catch (e) {
        } finally {
          window.setTimeout(() => {
            this.isLoading = false
          }, 250)
        }
      } else {
        this.isLoading = false
      }
    },
    isLogin () {
      console.log('app isLogin location storage' + window.localStorage.getItem('loginToken'))
      console.log('app isLogin session storage' + window.sessionStorage.getItem('loginToken'))
      return window.localStorage.getItem('loginToken') !== null || window.sessionStorage.getItem('loginToken') !== null
    }
  },
  watch: {
    isLoading (val, old) {
      if (!val && old) {
        if (!this.isLogin()) {
          this.$router.push('/login')
        } else if (this.isLogin() && (this.$route.fullPath === '/' || this.$route.fullPath === '/login')) {
          this.$router.push('/places')
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "styles/main";
</style>

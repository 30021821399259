import asyncComponent from '@/router/asyncComponent'
import Error from '@/pages/error/error'

export default [
  {
    path: '/login',
    name: 'Login',
    component: asyncComponent({
      loader: () => import('@/pages/auth/login')
    })
  },
  {
    path: '/register',
    name: 'Register',
    component: asyncComponent({
      loader: () => import('@/pages/auth/register')
    })
  },
  {
    path: '/places',
    name: 'Places',
    component: asyncComponent({
      loader: () => import('@/pages/places/places')
    })
  },
  {
    path: '/places/:id',
    name: 'Place',
    component: asyncComponent({
      loader: () => import('@/pages/place/place')
    })
  },
  {
    path: '/histories',
    name: 'Histories',
    component: asyncComponent({
      loader: () => import('@/pages/histories/histories')
    })
  },
  {
    path: '/histories/:id',
    name: 'History',
    component: asyncComponent({
      loader: () => import('@/pages/history/history')
    })
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error
  }
]

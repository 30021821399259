<template>
  <div class="splash">
    <img src="/images/splash-logo.png" alt=""/>
  </div>
</template>

<script>
export default {
  name: 'Splash',
  props: [
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>

export const Auth = {
  login: (email, password, device) => {
    return window.axios.post('auth/login', {
      email,
      password,
      device
    })
  },
  register: (name, email, password, code, device) => {
    return window.axios.post('auth/register', {
      name,
      email,
      device,
      password,
      invitation_code: code
    })
  },
  logout: () => {
    return window.axios.post('auth/logout')
  },
  setToken: (token) => {
    window.axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`
    }
  },
  changePassword: (currentpassword, newpassword, cnewpassword) => {
    return window.axios.post('auth/change_password', {
      currentpassword,
      newpassword,
      cnewpassword
    }, error => {
      console.log(error.response.data.errors)
    })
  }
}

export const User = {
  me: () => {
    return window.axios.get('user')
  },
  getCheckInMark: () => {
    return window.axios.get('/students/get-check-in-mark')
  },
  getSubmissionMark: () => {
    return window.axios.get('/students/get-submission-mark')
  }
}

export const Places = {
  searchMap: (lat, long, term, districts, tagId, tags, forms, nationalEducation) => {
    return window.axios.get('places/map', {
      params: {
        lat: `${lat}`,
        long: `${long}`,
        range: 10000000,
        term,
        tagId,
        districts,
        tags,
        forms,
        nationalEducation
      }
    })
  },
  get: (id) => {
    return window.axios.get('places/' + id)
  },
  checkIn: (id) => {
    return window.axios.post('places/checkin/' + id)
  },
  isCheckIn: (id) => {
    return window.axios.get('places/ischeckin/' + id)
  },
  isSubmit: (id) => {
    return window.axios.get('places/issubmit/' + id)
  }
}

export const Histories = {
  searchMap: (lat, long, term, districts, tagId, tags, forms, nationalEducation) => {
    return window.axios.get('histories/map', {
      params: {
        lat: `${lat}`,
        long: `${long}`,
        range: 10000000,
        term,
        tagId,
        districts,
        tags,
        forms,
        nationalEducation
      }
    })
  },
  get: (id) => {
    return window.axios.get('histories/' + id)
  },
  isSubmit: (id) => {
    return window.axios.get('histories/issubmit/' + id)
  }
}

export const Districts = {
  all: () => {
    return window.axios.get('districts')
  }
}

export const Tags = {
  all: (type) => {
    return window.axios.get('tags', {
      params: {
        type
      }
    })
  }
}

export const Grades = {
  all: () => {
    return window.axios.get('grades')
  }
}

export const Submissions = {
  create: (answers) => {
    return window.axios.post('submissions', {
      answers
    })
  }
}

export const Gifts = {
  all: () => {
    return window.axios.get('gifts/all')
  },
  redeem: () => {
    return window.axios.post('gifts/redeem')
  }
}

export const School = {
  classes: () => {
    return window.axios.get('classes')
  },
  enroll: (classId) => {
    return window.axios.post('enroll', {
      class_id: classId
    })
  }
}

import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import createPersistedState from 'vuex-persistedstate'

export default Vuex.createStore({
  modules: {
    auth
  },
  plugins: [createPersistedState()]
})

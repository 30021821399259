export default {
  namespaced: true,
  state: {
    data: null,
    language: 'zh-Hant',
    loading: false
  },
  getters: {
    isAuthed: (state) => {
      // return !!state.data
      // console.log('local Storage login token' + window.localStorage.getItem('loginToken'))
      // console.log('session storage login token' + window.sessionStorage.getItem('loginToken'))
      return (window.localStorage.getItem('loginToken') !== null || window.sessionStorage.getItem('loginToken') !== null) && !!state.data
    },
    me: (state) => {
      return state.data || {}
    },
    loading: (state) => {
      return state.loading
    },
    language: (state) => {
      return state.language
    }
  },
  mutations: {
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
    setLanguage: (state, language) => {
      state.language = language
    },
    setData: (state, data) => {
      state.data = data
    },
    addPoints: (state, points) => {
      state.data.points += points
    },
    removePoints: (state, points) => {
      state.data.points -= points
    },
    clearData: (state) => {
      state.data = null
    },
    updateData: (state, data) => {
      state.data = {
        ...state.data,
        ...data
      }
    }
  },
  actions: {
    login: ({ commit }, { token, user, remember }) => {
      commit('startLoading')
      // console.log('remember me ' + remember)
      if (remember === true) {
        window.localStorage.setItem('loginToken', token)
      } else if (remember === false) {
        window.sessionStorage.setItem('loginToken', token)
        window.localStorage.removeItem('loginToken')
      }
      commit('setData', user)
      commit('stopLoading')
    },
    addPoints: ({ commit }, { points }) => {
      commit('addPoints', points)
    },
    removePoints: ({ commit }, { points }) => {
      commit('removePoints', points)
    },
    logout: ({ commit }) => {
      commit('startLoading')
      window.localStorage.removeItem('loginToken')
      window.sessionStorage.removeItem('loginToken')
      commit('clearData')
      commit('stopLoading')
    },
    switchLanguage: ({ commit }, { language }) => {
      commit('setLanguage', language)
    },
    setLanguage: ({ commit }, language) => {
      commit('setLanguage', language)
    },
    updateUser: ({ commit }, data) => {
      commit('updateData', data)
    }
  }
}

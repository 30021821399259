import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueFinalModal from 'vue-final-modal'
import Toaster from '@meforma/vue-toaster'
import axios from 'axios'
import { endpoint } from '@/config/config'
import i18n from './config/i18n/i18n'
import mitt from 'mitt'
import './registerServiceWorker'
import VueLazyload from 'vue-lazyload'

// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

window.axios = axios.create({
  baseURL: endpoint,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

window.axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response?.status === 401) {
    const pages = ['login', 'auth/logout']
    if (new RegExp(pages.join('|') + '$').test(error.response.config.url)) {
      return Promise.reject(error)
    } else {
      localStorage.clear()
      sessionStorage.clear()
    }
  }

  return Promise.reject(error)
})

const appVue = createApp(App)

const emitter = mitt()

appVue.config.globalProperties.emitter = emitter
appVue.config.globalProperties.$filters = {
}
appVue.directive('plaintext', {
  created (el, binding, vnode) {
    el.innerHTML = el.innerText
  },
  updated (el, binding, vnode) {
    if (vnode.children[0]?.el?.innerText) {
      el.innerHTML = vnode.children[0]?.el.innerText
    }
  }
})
// Sentry.init({
//   appVue,
//   dsn: 'https://d9ca6bbfd418465497b65c6753f3f3b7@o455698.ingest.sentry.io/5702804',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router)
//     })
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })
// Sentry.attachErrorHandler(appVue, { logErrors: true })

appVue.use(i18n)
appVue.use(Toaster)
appVue.use(router)
appVue.use(store)
appVue.use(VueFinalModal())
appVue.use(VueLazyload)
appVue.mount('#app')

import { createI18n } from 'vue-i18n'

import en from './locales/en.json'
import hk from './locales/zh-HK.json'

const messages = {
  en: en,
  'zh-Hant': hk
}

export default createI18n({
  locale: 'zh-Hant', // set locale
  fallbackLocale: 'zh-Hant', // set fallback locale
  silentTranslationWarn: true,
  messages
})

import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes'

const router = createRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
